import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 22c-2.17 0-6.75-7.42-6.75-13.25C5.25 5.03 8.28 2 12 2s6.75 3.03 6.75 6.75C18.75 14.58 14.17 22 12 22m0-18.5a5.25 5.25 0 0 0-5.25 5.25c0 5.59 4.29 11.49 5.29 11.75.92-.26 5.21-6.16 5.21-11.75 0-2.9-2.35-5.25-5.25-5.25m0 8.69a3.19 3.19 0 0 1-3.19-3.18c0-1.75 1.43-3.19 3.19-3.19s3.19 1.43 3.19 3.19-1.43 3.18-3.19 3.18m0-4.87c-.93 0-1.69.76-1.69 1.69s.76 1.68 1.69 1.68 1.69-.76 1.69-1.68A1.7 1.7 0 0 0 12 7.32" }, null, -1)
  ])))
}
export default { render: render }