import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M3.251 10.241a.2.2 0 0 1-.25-.193L3 4a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6.048a.2.2 0 0 1-.251.193 3 3 0 0 0-.749-.095h-2c-.948 0-1.793.44-2.343 1.126a.2.2 0 0 1-.157.077.2.2 0 0 1-.156-.077A3 3 0 0 0 13 10.146h-2c-.948 0-1.793.44-2.343 1.126a.2.2 0 0 1-.157.077.2.2 0 0 1-.156-.077A3 3 0 0 0 6 10.146H4q-.388.001-.749.095M4.5 18.146h-2a.5.5 0 0 1-.5-.5v-4.5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2h-.5a3 3 0 0 0-3 3m1 3.354v-3.354a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2V21.5c0 .133-.053.26-.146.354A.5.5 0 0 1 11 22H6a.5.5 0 0 1-.354-.146.5.5 0 0 1-.146-.354m7 0v-3.354a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2V21.5c0 .133-.053.26-.146.354A.5.5 0 0 1 18 22h-5a.5.5 0 0 1-.354-.146.5.5 0 0 1-.146-.354M21 11.414c.598.346 1 .992 1 1.732v4.5a.5.5 0 0 1-.5.5h-2a3 3 0 0 0-3-3H16v-2a2 2 0 0 1 2-2h2c.364 0 .706.098 1 .268M9 13.146a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2h-.5a3 3 0 0 0-2.5 1.342 3 3 0 0 0-2.5-1.342H9z" }, null, -1)
  ])))
}
export default { render: render }