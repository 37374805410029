import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 18.75a.763.763 0 0 1-.75-.75V6a.748.748 0 0 1 1.17-.62l9 6a.75.75 0 0 1 0 1.24l-9 6a.72.72 0 0 1-.42.13M8.75 7.4v9.2l6.9-4.6z" }, null, -1)
  ])))
}
export default { render: render }