import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M4.496 10.244a.75.75 0 0 1-1.501 0V2.75A.75.75 0 0 1 3.746 2h16.508a.75.75 0 0 1 .751.75v7.494a.75.75 0 0 1-1.501 0V3.501H4.496zM6.985 21.25a.75.75 0 0 1-1.501 0v-1.535a2.72 2.72 0 0 1 2.718-2.718h.564a2.72 2.72 0 0 1 2.718 2.718v1.535a.75.75 0 0 1-1.501 0v-1.535a1.215 1.215 0 0 0-1.217-1.217h-.564c-.323 0-.632.128-.86.357a1.21 1.21 0 0 0-.357.86zm7.02 0a.751.751 0 0 1-1.501 0v-1.535a2.717 2.717 0 0 1 2.718-2.718h.564a2.717 2.717 0 0 1 2.717 2.718v1.535a.75.75 0 0 1-1.5 0v-1.535a1.216 1.216 0 0 0-1.217-1.217h-.565a1.214 1.214 0 0 0-1.216 1.217zm3.501-6.004a.75.75 0 1 1-1.5 0v-.534a2.715 2.715 0 0 1 2.717-2.717h.565a2.715 2.715 0 0 1 2.717 2.717v3.536a.75.75 0 0 1-1.501 0v-3.536a1.217 1.217 0 0 0-1.216-1.216h-.565a1.217 1.217 0 0 0-1.217 1.216zm-11.012 0v-.534a1.22 1.22 0 0 0-1.217-1.216h-.565a1.217 1.217 0 0 0-1.216 1.216v3.536a.751.751 0 0 1-1.501 0v-3.536a2.715 2.715 0 0 1 2.717-2.717h.565a2.715 2.715 0 0 1 2.717 2.717v.534a.75.75 0 1 1-1.5 0m4.091 0a.75.75 0 1 1-1.5 0v-.534a2.715 2.715 0 0 1 2.717-2.717h.565a2.715 2.715 0 0 1 2.717 2.717v.534a.75.75 0 1 1-1.5 0v-.534a1.22 1.22 0 0 0-1.217-1.216h-.565a1.217 1.217 0 0 0-1.217 1.216z" }, null, -1)
  ])))
}
export default { render: render }