import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16.01 3.5c.793 0 1.554.319 2.114.887.561.568.876 1.338.876 2.141v13.463c0 .354-.183.682-.483.865a.99.99 0 0 1-.981.026L12 17.903l-5.536 2.979a.99.99 0 0 1-.981-.026A1.01 1.01 0 0 1 5 19.991V6.528c0-.803.315-1.573.876-2.141A2.97 2.97 0 0 1 7.99 3.5zM6.495 19.151l4.804-2.585a1.48 1.48 0 0 1 1.402 0l4.804 2.585V6.528c0-.401-.157-.786-.438-1.07a1.48 1.48 0 0 0-1.057-.444H7.99c-.397 0-.777.16-1.057.444a1.52 1.52 0 0 0-.438 1.07z" }, null, -1)
  ])))
}
export default { render: render }